import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import Projects from "./components/Projects";
import Navbar from "./components/Navbar";
import TestProject from './pages/testproject';
import ArtMain from './pages/ArtMain';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
            <Route index element={
                <main className="text-gray-900 bg-slate-100 body-font">
                <Navbar />
                <About />
                <Projects />
                <Contact />
                </main>
            }/>
            <Route path="testproject" element={<TestProject />} />
            <Route path="art" element={<ArtMain />}/>
        </Route>
      </Routes>  
    </BrowserRouter>
  );
}