import RayTraceImage from './images/output.png';
import TestImage from './images/ficus.jpg'

export const projects = [
    {
        title: "Ray Tracer",
        subtitle: "(not in real-time)",
        description: "A project I did in a weekend. I wanted to practice my C++, so I wrote the whole thing from scratch (including the very basics, like the vector class, just for completeness). Implementing anti-aliasing and depth of field was challenging, but it was very satisfying to see the end result. Fun fact, there are no models in the image shown; everything here was done with signed distance fields and raytracing. Click to see the source on my Github!",
        image:RayTraceImage,
        link: "https://github.com/lairGit/RayTracer"
    },
    {
        title: "Test Game",
        subtitle: "An exercise in utility",
        description: "This was my day, huh?",
        image: TestImage,
        link: "/testproject"
    },
];