import React from "react";

export default function Navbar() {
    return (
        <header className="bg-fuchsia-900 md:sticky top-0 z-20">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <div className="title-font font-medium text-white mb-4 md:mb-0">
                <a href="#about" className="ml-3 text-xl">
                    Jack Lair
                </a>
                </div>
                <nav className="md:mr-auto md:ml-6 md:py-1 md:pl-6 md:border-l md:text-white flex flex-wrap items-center text-base justify-center">
                <a href="#projects" className="mr-6 hover:text-green-500">
                    Projects
                </a>
                <a href="/art" className="mr-6 hover:text-green-500">
                    Art
                </a>
                </nav>
                <a
                href="#contact"
                className="inline-flex items-center bg-green-500 border-0 py-1 px-3 focus:outline-none hover:bg-green-600 text-white rounded text-base mt-4 md:mt-0">
                Contact Me
                </a>
            </div>
        </header>
    )
}