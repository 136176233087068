import React from "react";
import Unity, {UnityContext} from "react-unity-webgl";
import '../TemplateData/webglstyle.css'

const unityContext = new UnityContext({
    loaderUrl: "builds/testProject/build/Builds.loader.js",
    dataUrl: "builds/testProject/build/Builds.data",
    frameworkUrl: "builds/testProject/build/Builds.framework.js",
    codeUrl: "builds/testProject/build/Builds.wasm",
});

export default function TestProject () {
    return (
        <body className="px-3 py-5">
            <a href="/" className="ml-4 inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-600 hover:text-white rounded text-lg">Home</a>
            <div id="unity-container" class="unity-desktop">
                <div>
                    <Unity unityContext={unityContext} style={{width:960, height:600}}/>
                </div>
            </div>
        </body>
    )
};